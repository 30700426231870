import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function NavStat() {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.pathname);
  return (
    <div className="nav">
      <div
        className={
          location.pathname === "/statistique de suivi"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          onClick={() => navigate("/statistique de suivi")}
          className="nav-link"
        >
          accueil
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/statgen"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          onClick={() => navigate("/statgen")}
          className="nav-link"
        >
          générales
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/statmodule"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          className="nav-link"
          onClick={() => navigate("/statmodule")}
        >
          modules
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/statautoecole"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          className="nav-link"
          onClick={() => navigate("/statautoecole")}
        >
          auto-école
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/statcandidat"
            ? "d-none"
            : "nav-item fs-5 "
        }
      >
        <div
          className="nav-link"
          onClick={() => navigate("/statcandidat")}
        >
          Candidats
        </div>
      </div>
    </div>
  );
}
