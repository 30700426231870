import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";

export default function DetailExamen({ list }) {
  
  const columns = [
    {
      name: 'ID Candidat',
      selector: row => row.unique_id_cand,
      sortable: true,
    },
    {
      name: 'Centre',
      width:"170px",
      selector: row => <div title={row.examens.organisme.nom_org}>{row.examens.organisme.nom_org}</div>,
      sortable: true,
    },
    {
      name: 'Note',
      selector: row => row.notet_cand +"/"+ row.notesurt_cand ,
      sortable: true,
    },
    {
      name: 'Temps (s)',
      selector: row => row.tempst_cand !== null ? row.tempst_cand : 'N/A',
      sortable: true,
    },
    {
      name: 'Remarque',
      selector: row => row.rmqt_cand !== null ? row.rmqt_cand : 'Aucune',
      sortable: true,
    },
    
    {
      name: 'ID Examen',
      selector: row => row.id_exam,
      sortable: true,
    },
  
    {
      name: 'Date et Heure de l\'Examen',
      width:"170px",
      selector: row => <div title={row.examens.date_exam+" à "+row.examens.heurs_exam}>{row.examens.date_exam} à {row.examens.heurs_exam}</div>,
      sortable: true,
    }
  ];

  const [examodal, setexamodal] = useState(false)
  const dataRedux = useSelector((state) => state.listtypeexam);
  const Type = (id) => {
    console.log(dataRedux);
    const element = dataRedux.find((element) => element.value == id);

    if (element !== undefined) {
      return `${element.label}`;
    } else {
      return `${id}`;
    }
  };
  return (
    <div>
      <div className="">
        <Button variant="info" onClick={()=>setexamodal(!examodal)}>Détails examen</Button>
      </div>
      <Modal autoFocus={true} backdrop="static" size="xl" show={examodal} onHide={()=>setexamodal(!examodal)}>
        <Modal.Header closeButton>Détails examen</Modal.Header>
        <Modal.Body>
          <DataTable
            noContextMenu
            className="custom-table"
            columns={columns}
            data={list}
            highlightOnHover
            responsive
            progressComponent={
              <div className="w-100 d-flex justify-content-center py-5">
                <TailSpin
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            }
            contextMessage={undefined}
            contextActions={null}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page",
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
