import image1 from "../imgtest/1.jpg"
import image2 from "../imgtest/2.jpg"
import image3 from "../imgtest/3.jpg"
import image4 from "../imgtest/4.jpg"
import image5 from "../imgtest/5.jpg"
import image6 from "../imgtest/6.jpg"
import metfp from  "./../metfp.png"
import mtm from  "./../mtm.png"
import pact from  "./../pact.png"
import att from  "./../att.jpg"
import svgExercice from "../../svg/Online test-amico.png"
import city from "./../../svg/City driver-rafiki.svg"
import webinar from "../../svg/Webinar-pana.svg"
import avatar from "../../image/avatar.jpg"
import io from "./io.svg"
import min from "./ministre.png"
export default {metfp,min,mtm,pact,image1,image2,image3,image4,image5,image6,svgExercice,att,city,webinar,avatar,io};