import {
  faBookOpenReader,
  faBoxArchive,
  faCarSide,
  faChartPie,
  faCheckCircle,
  faCommentAlt,
  faEdit,
  faEraser,
  faFemale,
  faGraduationCap,
  faHouse,
  faListNumeric,
  faMale,
  faRectangleXmark,
  faSquare,
  faSquareCaretUp,
  faSquarePlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import image from "../../../../assets/image/imgtest/image";
import DonutChart from "./DonutChart";
import NavStat from "./NavStat";

export default function StatModule() {
  const columns = [
    // {
    //   name: "ID",
    //   selector: (row) => <>{row.id}</>,
    //   sortable: true,
    //   width: "7%",
    // },
    {
      name: "Titre",
      selector: (row) => row.titre,
      sortable: true,
      width: "42%",
    },
    {
      name: "Langue",
      selector: (row) => row.langue,
      sortable: true,
      width: "13%",
    },
    {
      name: "Catégorie",
      selector: (row) => row.categorie,
      sortable: true,
      width: "13%",
    },
    {
      name: "Notes moyennes",
      selector: (row) => row.noteMoyenne,
      sortable: true,
      width: "13%",
    },
    {
      name: "action",
      cell: (row) => (
        <div className="d-flex gap-2">
          <button className="btn btn-secondary">Notes</button>
          <button className="btn btn-cyan" onClick={handle}>
            <FontAwesomeIcon icon={faCommentAlt} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const data = [
    {
      id: 1,
      titre: "Formation de conduite de base",
      langue: "français",
      categorie: "Permis B",
      noteMoyenne: 4.2,
    },
    {
      id: 2,
      titre: "Formation de conduite de nuit",
      langue: "français",
      categorie: "Permis B",
      noteMoyenne: 3.9,
    },
    {
      id: 3,
      titre: "Formation de conduite en montagne",
      langue: "malagasy",
      categorie: "Permis B",
      noteMoyenne: 4.5,
    },
    {
      id: 4,
      titre: "Formation de conduite en ville",
      langue: "français",
      categorie: "Permis B",
      noteMoyenne: 4.1,
    },
    {
      id: 5,
      titre: "Formation de conduite de poids lourd",
      langue: "malagasy",
      categorie: "Permis C",
      noteMoyenne: 3.8,
    },
  ];
  const [show, setshow] = useState(false);
  const handle = () => setshow(!show);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={show}
        onHide={handle}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Commentaire sur le module 1</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-4 py-1">
              <div className="card card-link card-link-pop">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${image.avatar})`,
                        }}
                      ></span>
                    </div>
                    <div className="col d-flex flex-column">
                      <h4 className="mb-0">RAKOTOMALALA</h4>
                      <div>
                      <span className="badge bg-cyan fs-6">20-04-2022 à 12:00</span>
                      </div>
                      <Rating
                        size={20}
                        showTooltip={false}
                        /* tooltipArray={tooltipArraylist}*/
                        titleSeparator="sur"
                        initialValue={0.5}
                        readonly={true}
                        /* Available Props */
                      />
                      <p>J'ai reussi a apprendre le base de la conduite grace a cette plateforme</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 py-1">
              <div className="card card-link card-link-pop">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${image.avatar})`,
                        }}
                      ></span>
                    </div>
                    <div className="col d-flex flex-column">
                      <h4 className="mb-0">RAKOTOMALALA</h4>
                      <div>
                      <span className="badge bg-cyan fs-6">20-04-2022 à 12:00</span>
                      </div>
                      <Rating
                        size={20}
                        showTooltip={false}
                        /* tooltipArray={tooltipArraylist}*/
                        titleSeparator="sur"
                        initialValue={0.5}
                        readonly={true}
                        /* Available Props */
                      />
                      <p>J'ai reussi a apprendre le base de la conduite grace a cette plateforme</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  <div className="col-lg-4 py-1">
              <div className="card card-link card-link-pop">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${image.avatar})`,
                        }}
                      ></span>
                    </div>
                    <div className="col d-flex flex-column">
                      <h4 className="mb-0">RAKOTOMALALA</h4>
                      <div>
                      <span className="badge bg-cyan fs-6">20-04-2022 à 12:00</span>
                      </div>
                      <Rating
                        size={20}
                        showTooltip={false}
                        /* tooltipArray={tooltipArraylist}*/
                        titleSeparator="sur"
                        initialValue={0.5}
                        readonly={true}
                        /* Available Props */
                      />
                      <p>J'ai reussi a apprendre le base de la conduite grace a cette plateforme</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  <div className="col-lg-4 py-1">
              <div className="card card-link card-link-pop">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${image.avatar})`,
                        }}
                      ></span>
                    </div>
                    <div className="col d-flex flex-column">
                      <h4 className="mb-0">RAKOTOMALALA</h4>
                      <div>
                      <span className="badge bg-cyan fs-6">20-04-2022 à 12:00</span>
                      </div>
                      <Rating
                        size={20}
                        showTooltip={false}
                        /* tooltipArray={tooltipArraylist}*/
                        titleSeparator="sur"
                        initialValue={0.5}
                        readonly={true}
                        /* Available Props */
                      />
                      <p>J'ai reussi a apprendre le base de la conduite grace a cette plateforme</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  <div className="col-lg-4 py-1">
              <div className="card card-link card-link-pop">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${image.avatar})`,
                        }}
                      ></span>
                    </div>
                    <div className="col d-flex flex-column">
                      <h4 className="mb-0">RAKOTOMALALA</h4>
                      <div>
                      <span className="badge bg-cyan fs-6">20-04-2022 à 12:00</span>
                      </div>
                      <Rating
                        size={20}
                        showTooltip={false}
                        /* tooltipArray={tooltipArraylist}*/
                        titleSeparator="sur"
                        initialValue={0.5}
                        readonly={true}
                        /* Available Props */
                      />
                      <p>J'ai reussi a apprendre le base de la conduite grace a cette plateforme</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  <div className="col-lg-4 py-1">
              <div className="card card-link card-link-pop">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${image.avatar})`,
                        }}
                      ></span>
                    </div>
                    <div className="col d-flex flex-column">
                      <h4 className="mb-0">RAKOTOMALALA</h4>
                      <div>
                      <span className="badge bg-cyan fs-6">20-04-2022 à 12:00</span>
                      </div>
                      <Rating
                        size={20}
                        showTooltip={false}
                        /* tooltipArray={tooltipArraylist}*/
                        titleSeparator="sur"
                        initialValue={0.5}
                        readonly={true}
                        /* Available Props */
                      />
                      <p>J'ai reussi a apprendre le base de la conduite grace a cette plateforme</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  <div className="col-lg-4 py-1">
              <div className="card card-link card-link-pop">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${image.avatar})`,
                        }}
                      ></span>
                    </div>
                    <div className="col d-flex flex-column">
                      <h4 className="mb-0">RAKOTOMALALA</h4>
                      <div>
                      <span className="badge bg-cyan fs-6">20-04-2022 à 12:00</span>
                      </div>
                      <Rating
                        size={20}
                        showTooltip={false}
                        /* tooltipArray={tooltipArraylist}*/
                        titleSeparator="sur"
                        initialValue={0.5}
                        readonly={true}
                        /* Available Props */
                      />
                      <p>J'ai reussi a apprendre le base de la conduite grace a cette plateforme</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  <div className="col-lg-4 py-1">
              <div className="card card-link card-link-pop">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${image.avatar})`,
                        }}
                      ></span>
                    </div>
                    <div className="col d-flex flex-column">
                      <h4 className="mb-0">RAKOTOMALALA</h4>
                      <div>
                      <span className="badge bg-cyan fs-6">20-04-2022 à 12:00</span>
                      </div>
                      <Rating
                        size={20}
                        showTooltip={false}
                        /* tooltipArray={tooltipArraylist}*/
                        titleSeparator="sur"
                        initialValue={0.5}
                        readonly={true}
                        /* Available Props */
                      />
                      <p>J'ai reussi a apprendre le base de la conduite grace a cette plateforme</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  <div className="col-lg-4 py-1">
              <div className="card card-link card-link-pop">
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${image.avatar})`,
                        }}
                      ></span>
                    </div>
                    <div className="col d-flex flex-column">
                      <h4 className="mb-0">RAKOTOMALALA</h4>
                      <div>
                      <span className="badge bg-cyan fs-6">20-04-2022 à 12:00</span>
                      </div>
                      <Rating
                        size={20}
                        showTooltip={false}
                        /* tooltipArray={tooltipArraylist}*/
                        titleSeparator="sur"
                        initialValue={0.5}
                        readonly={true}
                        /* Available Props */
                      />
                      <p>J'ai reussi a apprendre le base de la conduite grace a cette plateforme</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handle}>
            Annuler
          </Button>
          <Button variant="primary" onclick={handle}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex flex-nowrap justify-content-between w-100 align-items-center">
          <div>
            <h2>
              <FontAwesomeIcon icon={faHouse} className="me-1" /> Statistique de
              suivi{" "}
            </h2>
            <h4>Statistique Modules</h4>
          </div>
          <div>
            <NavStat />
          </div>
        </div>
      </div>
      <div className="card card-link card-link-pop">
        <div className="card-body">
          <h2>Filtres</h2>
          <div className="row">
            <div className="col-lg-2 col-12">
              <div className="my-2">
                <label className="form-label">Années</label>
                <input
                  type={"date"}
                  className="form-control w-100"
                  name="annéé"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="my-2">
                <label className="form-label">Modules</label>
                <select className="form-control w-100" name="autoecole">
                  <option value="">Module 1</option>
                  <option value="">Module 2</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4 col-12 align-self-end">
              <div className="my-2 d-flex">
                <button className="btn btn-cyan w-100 mx-1">Valider</button>
                <div className="vr w-1 bg-secondary h-100"></div>
                <button className="btn btn-success w-100 mx-1">Exporter</button>
              </div>
            </div>
          </div>
          <div className="row my-3 align-items-center">
            <div className="col-lg-6 col-12">
              <div
                className="w-100 d-flex justify-content-center"
                style={{ maxHeight: "280px" }}
              >
                <DonutChart taille={3} label={["Module1", "Module2"]} />
              </div>
            </div>
            <div className="col-lg-6 col-12 h-100">
              <div className="row">
                <div className="col-12 p-1 h-100">
                  <div className="card py-3">
                    <div className="card-body">
                      <div className="row text-center">
                        <div className="col-lg-6 col-12">
                          <div className="">
                            <h3>Taux des modules terminer</h3>
                            <div className="display-6 fw-bold">78%</div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div>
                            <h3>Total des modules</h3>
                            <div className="display-6 fw-bold">105</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-2">
        <DataTable className="custom-table"  
          columns={columns}
          data={data}
          contextActions={
            <button className="btn btn-cyan me-2">
              <FontAwesomeIcon icon={faCheckCircle} className="pe-2" /> Valider
            </button>
          }
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText:'Lignes par page'
          }}
          paginationRowsPerPageOptions={[5,10,15,20]}
        />
      </div>
    </div>
  );
}
