import {
  faCalendarTimes,
  faCancel,
  faCheckCircle,
  faClockFour,
  faEdit,
  faEraser,
  faFilePdf,
  faHouse,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { Calendar } from "primereact/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import {
  CATEGORIE_API,
  ExamenListTheo,
  getcentreByatt,
  organisme_API,
  validationpratiqueHateaos,
  validationtheoriesHateaos,
} from "../../../../service/config";
import Select from "react-select";
import { toast } from "react-toastify";
import UseEndpoint from "../../../../service/getApi/UseEndpoint";
import moment from "moment";
import { Tag } from "primereact/tag";
import SelectDistinct from "../../../../outils/SelectDistinct";
import Resultat from "./Resultat";
import Cherche from "../../../../outils/Cherche";
import isconnected from "../../../../service/isconnected";
import SelectFinalOne from "../../../../outils/Select/SelectFinalOne";

export default function Red() {
  const [recharger, setRecharger] = useState(false);
  const [centre, setcentre] = useState([]);
  const [choix, setChoix] = useState(null);
  const [error, setError] = useState(null);
  const [show, setshow] = useState({
    examen: false,
    resultat: false,
    clear: false,
  });
  const handleShow = (propr) => setshow({ ...show, [propr]: !show[propr] });
  const { data: examlist, load: loadlist } = UseEndpoint(
    ExamenListTheo(),
    "data"
  );
  console.log(examlist);
  const { data, load } = UseEndpoint(
    organisme_API + `${"?type_org=Examinateurs"}`,
    "data"
  );
  const { data: categ, load: loadcateg } = UseEndpoint(CATEGORIE_API, "data");

  const abortController = new AbortController();
  const cherche = (dat, row, champs, ret) => {
    for (let i = 0; i < dat.length; i++) {
      const element = dat[i];
      if (element[champs] == row[champs]) {
        return element[ret];
      }
    }
  };
  const columns = [
    // {
    //   name: "ID",
    //   maxWidth: "80px",
    //   selector: (row) => row.id_exam,
    //   sortable: true,
    // },
    {
      name: "Centre",
      sortable: true,
      selector: (row) => row.id_org,
      cell: (row) => {
        const nom = cherche(data, row, "id_org", "nom_org");
        return <span>{nom}</span>;
      },
    },
    {
      name: "Catégorie de permis",
      sortable: true,
      selector: (row) => row.id_categ,
      format: (row) => {
        const nom = cherche(categ, row, "id_categ", "categ");
        return <Tag severity={"info"}> {nom}</Tag>;
      },
    },
    {
      name: "Date de l'examen",
      cell: (row) => moment(row.date_exam).locale("fr").format("LL"),
      selector: (row) => row.date_exam,
      sortable: true,
    },
    {
      name: "heure de début",
      selector: (row) => (
        <Tag severity={"info"}>
          <FontAwesomeIcon className="me-2" icon={faClockFour} />
          {row.heurs_exam}
        </Tag>
      ),
      sortable: true,
    },
  ];

  const columns_valid = [
    {
      name: "Nom et prenom",
      selector: (row) => (
        <>
          {row.nom} {row.prenom}
        </>
      ),
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.telephone,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      width: "105px",
      name: "statut",
      selector: (row) => (
        <span className="badge bg-secondary">{row.status}</span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="">
          <button className="btn btn-primary" onClick={() => {}}>
            <FontAwesomeIcon icon={faEraser} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [showvalid, setshowvalid] = useState(false);
  const [ExamSelected, setExamSelected] = useState([]);

  const [filtre, setfiltre] = useState({});
  const [autoecole, setautoecole] = useState([]);
  const handleValidation = () => setshowvalid(!showvalid);

  const handleFiltre = (e) => {
    const { name, value } = e.target;
    setfiltre({ ...filtre, [name]: value });
  };

  const getAutoecole = () => {
    let auto = [];
    try {
      axios
        .get(organisme_API + "?type_org=Auto-écoles", {
          signal: abortController.signal,
        })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            auto.push({ label: element.nom_org, value: element.id_org });
          }
          setautoecole(auto);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste auto-école");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };

  const getcentre = () => {
    let cent = [];
    try {
      axios
        .get(getcentreByatt + "Examinateurs", {
          signal: abortController.signal,
        })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            cent.push({ label: element.nom_org, value: element.id_org });
          }
          setcentre(cent);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste centre");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };

  useEffect(() => {
    getcentre();
    if (isconnected.user().type_util !== "Auto-écoles") {
      getAutoecole();
    }
  }, []);

  useEffect(() => {
    console.log(ExamSelected);
    setRecharger(!recharger);
  }, [ExamSelected]);

  useEffect(() => {
    setRecharger(!recharger);
  }, [filtre?.value, filtre?.statut]);

  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={showvalid}
        onHide={handleValidation}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header>
          <Modal.Title>
            <h2>Validation de liste des candidats selectionnés à l'examen </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="">
              <DataTable
                className="custom-table"
                title={<p className="fs-2">Liste des candidats </p>}
                columns={columns_valid}
                data={examlist}
                progressPending={loadlist}
                contextActions={
                  <button className="btn btn-cyan me-2">
                    <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                    Valider
                  </button>
                }
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleValidation}>
            Fermer
          </Button>
          <Button variant="primary">
            <>Envoyer</>
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row g-3">
        <div className="col-12">
          <Card>
            <Card.Body>
              <h1 className="text-start">Redoublants / Examens théoriques</h1>
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="my-2">
                    <label className="form-label">Centre d'examen</label>
                    <SelectFinalOne
                      options={centre}
                      load={false}
                      value={"value"}
                      label={"label"}
                      choix={choix}
                      placeholder="choix centre"
                      setFormValues={setfiltre}
                      formValues={filtre}
                      setError={setError}
                      error={error}
                      name={"centre"}
                      seteventy={setRecharger}
                    />
                  </div>
                </div>
                {isconnected.user().type_util !== "Auto-écoles" && (
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="my-2">
                      <label className="form-label">Auto-école</label>
                      <SelectFinalOne
                        options={autoecole}
                        load={false}
                        value={"value"}
                        label={"label"}
                        choix={choix}
                        placeholder="choix auto-école"
                        setFormValues={setfiltre}
                        formValues={filtre}
                        setError={setError}
                        error={error}
                        name={"value"}
                        seteventy={setRecharger}
                      />
                    </div>
                  </div>
                )}
                {/* <div className="col-lg-4 col-12">
                  <div className="my-2">
                    <label className="form-label">Statut :</label>
                    <select className="form-control w-100"  value={filtre?.statut}
                            onChange={handleFiltre} name="statut">
                      <option value="0">Réussites</option>
                      <option value="1">Echecs</option>
                    </select>
                  </div>
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12">
          <Modal
            size="xl"
            style={{ zIndex: "30 !important" }}
            show={show.examen}
            onHide={() => handleShow("examen")}
            backdrop={"static"}
            scrollable
          >
            <Modal.Header>
              <h1>Choisir un examen</h1>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <DataTable
                  title={
                    <div className="mb-4 mt-3">
                      <div
                        className="row justify-content-end g-3"
                        style={{ minWidth: "80%" }}
                      >
                        {" "}
                        <div className="col">
                          <label className="form-label">Centre d'examen</label>
                          <select
                            className="form-control w-100"
                            name="centrenom"
                            value={filtre?.centrenom}
                            onChange={handleFiltre}
                          >
                            <option value="">Selectionner centre...</option>
                            {SelectDistinct(
                              examlist,
                              "id_org",
                              "nom_org"
                            )?.distinctData?.map((el, i) => (
                              <option value={el.id_org}>
                                {Cherche(data, el, "id_org", "nom_org")}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col">
                          <label className="form-label">Catégorie permis</label>
                          <select
                            className="form-control w-100"
                            name="categ"
                            value={filtre?.categ}
                            onChange={handleFiltre}
                          >
                            <option value="">Selectionner catégorie...</option>
                            {SelectDistinct(
                              examlist,
                              "id_categ",
                              "categ"
                            )?.distinctData.map((el, i) => (
                              <option value={el.id_categ}>
                                {Cherche(categ, el, "id_categ", "categ")}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col">
                          <label htmlFor="" className="form-label">
                            Date d'examen :(choisir entre deux dates)
                          </label>
                          <Calendar
                            style={{ height: "35px" }}
                            locale={"fr"}
                            value={filtre?.date}
                            name="date"
                            onChange={handleFiltre}
                            selectionMode="range"
                            readOnlyInput
                            showButtonBar
                            showIcon
                          />
                        </div>
                        {/* <div className="col">
                          <label className="form-label">Date d'examen</label>
                          <input className="form-control" type="date" />
                        </div> */}
                      </div>
                    </div>
                  }
                  className="custom-table"
                  columns={columns}
                  data={examlist
                    .filter((f) =>
                      filtre?.centrenom && f.id_org != null
                        ? f.id_org == filtre?.centrenom
                        : f
                    )
                    .filter((f) =>
                      filtre?.categ && f.id_categ != null
                        ? f.id_categ == filtre?.categ
                        : f
                    )
                    .filter((item) => {
                      const date = filtre?.date ? filtre?.date : ["", ""];
                      const itemDate = new Date(item.date_exam);
                      const start = new Date(date[0]);
                      const end = new Date(date[1]);
                      return date[0] && date[1]
                        ? itemDate >= start && itemDate <= end
                        : item;
                    })}
                  selectableRows
                  clearSelectedRows={show.clear}
                  selectableRowsHighlight
                  selectableRowsSingle
                  onSelectedRowsChange={(row) =>
                    setExamSelected(row.selectedRows)
                  }
                  contextActions={
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-secondary me-2"
                        type="button"
                        onClick={() => handleShow("clear")}
                      >
                        Décocher
                      </button>
                      <button
                        className="btn btn-cyan me-2"
                        type="button"
                        onClick={() => handleShow("examen")}
                      >
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="pe-2"
                        />{" "}
                        Valider
                      </button>
                    </div>
                  }
                  progressPending={loadlist}
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  pagination
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: "Lignes par page",
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
              {/* <div className="d-flex justify-content-end gap-2">
                <button className="btn btn-secondary">
                  <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                  Exporter en PDF
                </button>
                <button className="btn btn-success">
                  <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                  Valider
                </button>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              {" "}
              <button
                onClick={() => handleShow("examen")}
                className="btn btn-secondary"
              >
                <FontAwesomeIcon icon={faCancel} className="me-2" />
                Annuler
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="col-12">
          <Resultat
            recharger={recharger}
            data={ExamSelected}
            listcentre={data}
            categ={categ}
            dataecole={autoecole}
            filtre={filtre}
            auto={filtre}
          />
        </div>
      </div>
    </div>
  );
}
