import React, { useState } from "react";
import "./Print.css";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import "moment/locale/fr";
import Cherche from "../../outils/Cherche";
import XlsPrint from "./XlsPrint";

export default function PrintResTheorie({
  list,
  data,
  listcentre,
  categ,
  dataecole,
  auto,
  nom,
}) {
  const ref = React.useRef();
  const [open, setopen] = useState(false);
  return (
    <>
     <div className="d-flex gap-3">
     <ReactToPrint
        trigger={() => (
          <Button variant="success">
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Imprimer
          </Button>
        )}
        content={() => ref.current}
      />
      <XlsPrint list={list}/>
     </div>
      <div className="d-none">
        <div className="row justify-content-center">
          <div className="col-6">
            <div ref={ref}>
              <div>
                <h1 className="mb-4"> Résultat pour l'examen théorique</h1>
                <div className="text-wrap fs-4 my-3">
                  {data[0]?.id_exam ? (
                    <React.Fragment>
                      <span className="py-5 fw-bold">Centre: </span> {nom}{" "}
                      <br />
                      <span className="py-5 fw-bold">Date d'examen: </span>
                      {moment(data[0]?.date_exam)
                        .locale("fr")
                        .format("LL")}{" "}
                      <br />
                      <span className="py-5 fw-bold">Catégorie: </span>
                      {Cherche(categ, data[0], "id_categ", "categ")} <br />
                    </React.Fragment>
                  ) : null}
                  {auto.value && data[0]?.id_exam ? (
                    <React.Fragment>
                      <span className="py-5 fw-bold">Auto-école: </span>
                      {Cherche(dataecole, auto, "value", "label")} <br />
                    </React.Fragment>
                  ) : null}
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom & Prénom</th>
                      <th>Auto-école</th>
                      <th>Remarque</th>
                      <th>Temps</th>
                      <th>Note</th>
                      <th>Resultat final</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((candidat) => (
                      <tr key={candidat?.unique_id_cand}>
                        <td>{candidat?.unique_id_cand}</td>
                        <td>
                          {candidat?.candidat?.nom_cand +
                            " " +
                            candidat?.candidat?.prenom_cand}
                        </td>
                        <td>{candidat?.candidat?.organisme?.nom_org || ""}</td>
                        <td>{candidat?.rmqt_cand}</td>
                        <td>{candidat?.tempst_cand}</td>
                        <td>
                          {candidat?.notet_cand + "/" + candidat?.notesurt_cand}
                        </td>
                        <td>{candidat?.candidat?.status_examen_cand}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={open}
        onHide={() => setopen(!open)}
        animation
        scrollable
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body></Modal.Body>
      </Modal>
    </>
  );
}
