import moment from 'moment';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const XlsPrint = ({ list }) => {
  const [fileName, setFileName] = useState('');

  const exportToExcel = () => {
    const currentDate = moment().format('YYYYMMDD_HHmmss');;
    const formattedDate = currentDate

    const newFileName = `Liste_${formattedDate}`;
    setFileName(newFileName);

    const titleRow = [{ t: 's', v: 'Liste de candidats', s: { font: { bold: true } } }]; // Title row with bold formatting
    const space = [""]
    const headerRow = ['ID', 'Nom & Prénom', 'Auto-école', 'Temps', 'Note', 'Resultat final']; // Header row

    // Add title row, header row, and data rows
    const data = [
        space,
      titleRow,
      space,
      headerRow,
      ...list.map((candidat) => [
        candidat.unique_id_cand,
        `${candidat.candidat.nom_cand} ${candidat.candidat.prenom_cand}`,
        candidat?.candidat?.organisme.nom_org || '',
        candidat.tempsp_cand,
        `${candidat.notep_cand}/${candidat.notesurp_cand}`,
        candidat.candidat.status_examen_cand,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `${newFileName}.xlsx`);
  };

  return (
    <div>
      <button className='btn btn-secondary' onClick={exportToExcel}>Exporter en xls</button>
    </div>
  );
};

export default XlsPrint;
