/* eslint-disable no-console */
import React from "react";
import { useState } from "react";
import { Navigate, Routes, useLocation, useNavigate } from "react-router-dom";
import { Route } from "react-router-dom";
import Accueil from "./components/Accueil/Accueil";
import Login from "./components/Authentification/Login";
import Contact from "./components/Contact/Contact";
import Exercices from "./components/Exercices/Exercices";
import Footer from "./components/Footer/Footer";
import Header from "./components/header/Header";
import AccueilModule from "./components/modules/Accueil_module";
import Commentaires from "./components/modules/Commentaires";
import DetailsModule from "./components/modules/Details_module";
import ListModule from "./components/modules/List_module";
import Inscription from "./components/Authentification/Inscription";
import { ToastContainer } from "react-toastify";
import "./App.css";
import GererModule from "./components/Admin/module/GererModule";
import GererLesson from "./components/Admin/lessons/GererLesson";
import AuthApi from "./service/auth/AuthApi";
import ProtectedRoute from "./service/auth/PrivateRoute";
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import isconnected from "./service/isconnected";
import RecherchePage from "./components/recherchepage/RecherchePage";
import BoiteNotification from "./components/Notification/BoiteNotification";
import Pointers from "./components/Admin/pointer/Pointers";
import Pointer from "./components/Admin/pointer/Pointer";
import Dashboard from "./components/Admin/Dashboard";
import VerifyEmail from "./components/Authentification/VerifyEmail";
import Categories from "./components/Admin/categorie/Categories";
import NotFound from "./components/404/NotFound";
import Evolution from "./components/Compte/Grand Public/Evolution";
import GererLessonFichier from "./components/Admin/lessons/GererLessonFichier";
import GererQuestionnaire from "./components/Admin/questionnaire/GererQuestionnaire";
import GererQuestionnaireFichier from "./components/Admin/questionnaire/GererQuestionnaireFichier";
import axios from "axios";
import { LESSON_API, moduleByLesson } from "./service/config";
import ModuleLesson from "./components/Admin/module/ModuleLesson";
import ListeReponse from "./components/Admin/questionnaire/ListeReponse";
import ListeReponseFich from "./components/Admin/questionnaire/ListeReponseFich";
import Lessons from "./components/Lessons/Lessons";
import Aleatoire from "./components/Exercices/Aleatoire";
import Error from "./components/Authentification/ErrorMail";
import Comment_Les from "./components/Lessons/Comment_Les";
import ExoModule from "./components/Exercices/ExoModule";
import LessonContext from "./service/context/LessonContext";
import GererSens from "./components/Admin/Sensibilisation/GererSens";
import GererActu from "./components/Admin/Actualité/GererActu";
import GererTexteLois from "./components/Admin/TexteLois/GererTexteLois";
import GererTexteLoisFichier from "./components/Admin/TexteLois/GererTexteLoisFichier";
import Text_Lois from "./components/Texte et lois/Text_Lois";
import TousActu from "./components/Accueil/section/TousActu";
import Details_actu from "./components/Accueil/section/Details_actu";
import Details_sens from "./components/Accueil/section/Details_sens";
import TousSens from "./components/Accueil/section/TousSens";
import List_Text_Lois from "./components/Texte et lois/List_Text_Lois";
import AddModifActu from "./components/Admin/Actualité/AddModifActu";
import AjoutModifLesson from "./components/Admin/lessons/AjoutModifLesson";
import Testyyy from "./Testyyy";
import socket from "./service/Socket_Connection";
import NotificationAdmin from "./components/Notification/NotificationAdmin";
import ReponseNotifAdmin from "./components/Notification/ReponseNotifAdmin";
import Notif from "./service/context/NotifContext";
import Organisme from "./components/Admin/Organisme/Organisme";
import Auto_Ecole from "./components/Admin/Auto-ecole/Auto_Ecole";
import AccueilORG from "./components/Admin/Organisme/AccueilORG";
import MembreOrganisme from "./components/Admin/Organisme/MembreOrganisme";
import Candidats from "./components/Admin/Auto-ecole/Candidat";
import Apprenants from "./components/Admin/Auto-ecole/Apprenants";
import Examens_Avec_Att from "./components/Admin/Organisme/Examens_Avec_Att";
import Resultats from "./components/Admin/Organisme/Resultats";
import GestionSMS from "./components/Admin/Organisme/GestionSMS";
import ModuleExercice from "./components/Admin/module/ModuleExercice";
import AccueilStat from "./components/Admin/Organisme/Statistique de suivi/AccueilStat";
import StatGeneral from "./components/Admin/Organisme/Statistique de suivi/StatGeneral";
import StatModule from "./components/Admin/Organisme/Statistique de suivi/StatModule";
import StatAutoecole from "./components/Admin/Organisme/Statistique de suivi/StatAutoecole";
import StatCandidat from "./components/Admin/Organisme/Statistique de suivi/StatCandidat";
import ScrollToTopOnMount from "./service/remonter";
import jwtDecode from "jwt-decode";
import SelectCustom from "./outils/Select/SelectComponent";
import CategApi, { GetCategorie } from "./service/getApi/CategApi";
import { getCompte } from "./service/getApi/getCompte";

import { gettype } from "./service/getApi/getTypeExamen";
import ValidationPratique from "./components/Admin/Organisme/ValidationPratique";
import ExamenTheorique from "./components/Admin/Organisme/Centre d'examen/ExamenTheorique";
import ExamenPratique from "./components/Admin/Organisme/Centre d'examen/ExamenPratique";
import CentreExamen from "./components/Admin/Organisme/Centre d'examen/CentreExamen";
import GererMotMinistere from "./components/Admin/Mot du Ministere accueil/GererMotMinistere";
import Examinateurs from "./components/Admin/Organisme/Examinateur/Examinateurs";
import Cartographie from "./components/Admin/Organisme/Cartographie/Cartographie";
import CartographieLocalisation from "./components/Admin/Organisme/Cartographie/CartographieLocalisation";
import PratiquesRoutes from "./components/Admin/Auto-ecole/Examen/Pratique/PratiqueRoutes";
import CandAffected from "./components/Admin/Auto-ecole/Examen/Pratique/CandAffected";
import CandValid from "./components/Admin/Auto-ecole/Examen/Pratique/CandValid";
import CandApte from "./components/Admin/Auto-ecole/Examen/Pratique/CandApte";
import TheorieRoutes from "./components/Admin/Auto-ecole/Examen/theorique/TheorieRoutes";
import CandtApte from "./components/Admin/Auto-ecole/Examen/theorique/CandtApte";
import CandAttente from "./components/Admin/Auto-ecole/Examen/theorique/CandAttente";
import CandEnvoyer from "./components/Admin/Auto-ecole/Examen/theorique/CandEnvoyer";
import CandValider from "./components/Admin/Auto-ecole/Examen/theorique/CandValider";
import CandidatByCentre from "./components/Admin/Organisme/Centre d'examen/CandidatByCentre";
import GererParamNotes from "./components/Admin/GererExamen/GererParamNotes";
import "./loader.css";
import Res from "./components/Admin/Organisme/ResultatExamen/Res";
import Moniteur from "./components/Admin/Organisme/Moniteur/Moniteur";
import Historique from "./components/Admin/Historique/Historique";
import ResPratique from "./components/Admin/Organisme/ResultatExamen/ResPratique";
import MenuPratique from "./components/Admin/Organisme/Cartographie/MenuPratique";
import Red from "./components/Admin/Organisme/Redoublant/Red";
import RedPratique from "./components/Admin/Organisme/Redoublant/RedPratique";
import Account from "./components/Compte/Grand Public/Account";
import CandidatOnCimPra from "./components/Admin/Organisme/CIM/CandidatOnCimPra";
AuthApi.setup();
export default function App() {
  const [test, settest] = useState("Français");
  const [Time, setTime] = useState(0);
  const [notif, setnotif] = useState("");
  const [rech, setrech] = useState("");
  const [load_categorie, setload_categorie] = useState("");
  const navigate = useNavigate();
  // eslint-disable-next-line
  var prevScrollpos = window.pageYOffset;
  const redir = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const red = urlParams.get("red");
    if (red) {
      window.localStorage.setItem("authToken", red);
      AuthApi.setAxiosToken(red);
      const test_token = jwtDecode(red);
      console.log(test_token);
      if (test_token) {
        navigate("/", { replace: true });
        window.location.reload();
      }
      return false;
    }
  };

  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-60px";
    }
    prevScrollpos = currentScrollPos;
  };
  const options = [
    { value: "1", label: "io" },
    { value: "1", label: "io" },
    { value: "1", label: "io" },
  ];
  const [show, setShow] = useState(true);
  const [actif, setactif] = useState(false);
  let dataLes = [];
  const [lessons, setLessons] = useState([]);

  const Wrapper = ({ children }) => {
    const location = useLocation();

    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return children;
  };

  useEffect(() => {
    gettype();
    getCompte();
    GetCategorie();
    redir();
    AuthApi.setup();

    socket.auth = {
      username:
        isconnected.user().email === undefined
          ? "olo"
          : isconnected.user().email !== undefined &&
            isconnected.user().type_util === "SuperAdmin"
          ? isconnected.user().type_util
          : isconnected.user().email,
    };
    socket.connect();
    setactif(isconnected.Authenticated());
  }, []);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="section1 app">
      {/* <div className="open-loading"></div> */}

      {
        <Routes>
          <Route
            element={
              <div className="d-flex flex-column justify-content-between h-100">
                <Header
                  setrech={setrech}
                  setload_categorie={setload_categorie}
                  settest={settest}
                  setactif={setactif}
                />{" "}
                <Footer />
              </div>
            }
          >
            <Route path="/notification" element={<BoiteNotification />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/rechercher/:id"
              element={<RecherchePage rech={rech} />}
            />
            <Route path="/commentaires/:id" element={<Commentaires />} />
            <Route path="/commentaires lesson/:id" element={<Comment_Les />} />
            <Route
              exact
              path="/acceuil"
              element={<Accueil test={test} funcNav={setShow} />}
            />
            <Route path="/détails actualités/:id" element={<Details_actu />} />

            <Route
              path="/tous les message de sensibilisation"
              element={<TousSens />}
            />
            <Route exact path="/tous les actualités" element={<TousActu />} />
          </Route>

          <Route path="/verify" element={<VerifyEmail />} />
          <Route path="/errorMail" element={<Error />} />
          <Route path="/inscription" element={<Inscription />} />

          <Route path="/login" element={<Login funcNav={setShow} />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/test" element={<Testyyy />} />

          <Route element={<ProtectedRoute.OrganismeRoute />}>
            <Route path="/gerer_message_all" element={<GererSens />} />
            <Route
              path="/détails sensibilisation/:id"
              element={<Details_sens />}
            />
            <Route path="*" element={<NotFound />} />
            <Route path="/Account" element={<Account />} />
            <Route path="/centre" element={<CentreExamen />} />
            <Route path="/candidatCentre" element={<CandidatByCentre />} />
            <Route path="/auto-école" element={<Auto_Ecole />} />
            <Route path="/statistique de suivi" element={<AccueilStat />} />
            <Route path="/statgen" element={<StatGeneral />} />
            <Route path="/statmodule" element={<StatModule />} />
            <Route path="/statautoecole" element={<StatAutoecole />} />
            <Route path="/gerer" element={<Organisme />} />
            <Route path="/cartographie" element={<Cartographie />} />
            <Route path="/historique" element={<Historique />} />
            <Route path="/localisation" element={<MenuPratique />} />
            <Route path="/statcandidat" element={<StatCandidat />} />
            <Route path="/" element={<AccueilORG />} />
            <Route path="/membres" element={<MembreOrganisme />} />
            <Route path="/pratiques" element={<PratiquesRoutes />}>
              <Route path="affecter" element={<CandAffected />} />
              <Route path="valider" element={<CandValid />} />
              <Route path="" element={<CandApte />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/theories" element={<TheorieRoutes />}>
              <Route path="attente" element={<CandAttente />} />
              <Route path="envoyer" element={<CandEnvoyer />} />
              <Route path="valid" element={<CandValider />} />
              <Route path="" element={<CandtApte />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/apprenants" element={<Apprenants />} />
            <Route path="/moniteurs" element={<Moniteur />} />
            <Route path="/exam_theories" element={<ExamenTheorique />} />
            <Route path="/exam_pratiques" element={<ExamenPratique />} />
            <Route path="/examens_theoriques" element={<Examens_Avec_Att />} />
            <Route path="/examens_pratiques" element={<ValidationPratique />} />
            <Route path="/resultats" element={<Res />} />
            <Route path="/resultats_pratique" element={<ResPratique />} />
            <Route path="/redoublants" element={<Red />} />
            <Route path="/redoublant_pratique" element={<RedPratique />} />

            <Route path="/gestion sms" element={<GestionSMS />} />
            <Route path="/cursus" element={<Pointers />} />
            <Route path="/cursus/:id" element={<Pointer />} />
            <Route path="/mot_min" element={<GererMotMinistere />} />
            <Route path="/examinateur" element={<Examinateurs />} />
            <Route path="/GererNote" element={<GererParamNotes />} />
            <Route path="/cimVerifycandidatsrefused" element={<CandidatOnCimPra />} />
            <Route path="/cimVerifycandidatsvalidated" element={<CandidatOnCimPra />} />
            <Route path="/cimVerifycandidat" element={<CandidatOnCimPra />} />
          </Route>
        </Routes>
      }
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
