import { faCheckCircle, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag } from "primereact/tag";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import UseEndpoint from "../../../../service/getApi/UseEndpoint";
import {
  ResPassPratique,
  ResPassTheo,
  ResRedoublePratique,
  ResRedoubleTheo,
} from "../../../../service/config";
import Cherche from "../../../../outils/Cherche";
import moment from "moment";
import { Button } from "react-bootstrap";
import PrintResPratique from "../../../../service/Print/PrintResPratique";

export default function ResultatPratique({
  recharger,
  data,
  listcentre,
  categ,
  dataecole,
  auto,
  filtre,
}) {
  const [nom, setnom] = useState({});
  const { data: list, load } =
 UseEndpoint(
          ResRedoublePratique(),
          "data",
          recharger,
          {
            id_org: filtre?.value || null,
            id_exam: data[0]?.id_exam || null,
            id_cent: filtre?.centre || null
          }
        )
   

  const columns = [
    {
      name: "ID Candidat",
      maxWidth: "150px",
      selector: (row) => row.unique_id_cand,
      sortable: true,
    },
    {
      name: "Nom & prénom",
      wrap: true,
      selector: (row) =>
        row.candidat["nom_cand"] + " " + row.candidat["prenom_cand"],
      sortable: true,
    },
    {
      name: "Auto-école",
      wrap: true,
      selector: (row) =>
        row?.candidat?.organisme?.nom_org,
      sortable: true,
    },
    {
      name: "Centre",
      wrap: true,
      selector: (row) =>
        nom?.nom,
      sortable: true,
    },
    {
      name: "Lieu",
      wrap: true,
      selector: (row) =>
        nom?.lieu,
      sortable: true,
    },
    {
      name: "Tempsp",
      selector: (row) => (row.tempsp_cand ? row.tempsp_cand : "Aucun info"),
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.notep_cand + "/" + row.notesurp_cand,
      sortable: true,
    },
    {
      name: "statut",
      minWidth: "200px",
      selector: (row) => (
        <Tag severity={"success"}>{row.candidat["status_examen_cand"]}</Tag>
      ),
    },
    // {
    //   name: "Info du candidat",
    //   cell: (row) => <Button>Voir</Button>,
    //   sortable: true,
    // },
  ];

  useEffect(() => {
    let centre = Cherche(listcentre, data[0], "id_org", "nom_org");
    let lieu = Cherche(listcentre, data[0], "id_org", "adresse_org");
    console.log(data[0])
    console.log(listcentre)
    setnom({
      nom:centre,
      lieu:lieu
    });
  }, [data]);

  return (
    <div>
      <div className="">
        <DataTable
          title={
            <div className="mb-4 mt-3">
              <div className="row justify-content-end g-3">
                {" "}
                <div className="col-12 d-flex justify-content-between ">
                  <div className="text-wrap fs-4">
                    <span className="fs-2 h1">Examen Pratique</span> <br />
                    {data[0]?.id_examp ? (
                      <React.Fragment>
                        <span className="py-5 fw-bold">Centre: </span> {nom?.nom}{" "}
                        <br />
                        <span className="py-5 fw-bold">Date d'examen: </span>
                        {moment(data[0]?.date_examp)
                          .locale("fr")
                          .format("LL")}{" "}
                        <br />
                        <span className="py-5 fw-bold">Catégorie: </span>
                        {Cherche(categ, data[0], "id_categ", "categ")} <br />
                      </React.Fragment>
                    ) : null}
                    {auto.value && data[0]?.id_examp ? (
                      <React.Fragment>
                        <span className="py-5 fw-bold">Auto-école: </span>
                        {Cherche(dataecole, auto, "value", "label")} <br />
                      </React.Fragment>
                    ) : null}
                  </div>
                  <div>
                    {list.length > 0 && load == false && (
                      <PrintResPratique
                        list={list}
                        auto={auto}
                        categ={categ}
                        data={data}
                        dataecole={dataecole}
                        nom={nom?.nom}
                        listcentre={listcentre}
                      />
                    )}
                  </div>
                </div>
                {/* <div className="col">
                          <label className="form-label">Date d'examen</label>
                          <input className="form-control" type="date" />
                        </div> */}
              </div>
            </div>
          }
          className="custom-table"
          columns={columns}
          data={list}
          progressPending={load}
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText: "Lignes par page",
          }}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      </div>
      {list.length > 0 && (
        <div className=" my-2 d-flex justify-content-end gap-2">
          {/* <button className="btn btn-secondary">
            <FontAwesomeIcon icon={faFilePdf} className="me-2" />
            Exporter en PDF
          </button> */}
          {/* <button className="btn btn-success">
                  <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                  Valider
                </button> */}
        </div>
      )}
    </div>
  );
}
