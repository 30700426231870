import { faCheckCircle, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag } from "primereact/tag";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import UseEndpoint from "../../../../service/getApi/UseEndpoint";
import { ResPassTheo, ResRedoubleTheo } from "../../../../service/config";
import Cherche from "../../../../outils/Cherche";
import moment from "moment";
import { Button } from "react-bootstrap";
import PrintResTheorie from "../../../../service/Print/PrintResTheorie";
import DetailExamen from "../ResultatExamen/DetailExamen";

export default function Resultat({
  recharger,
  data,
  listcentre,
  categ,
  dataecole,
  auto,
  filtre,
}) {
  const [nom, setnom] = useState({});
  const { data: list, load } =
   UseEndpoint(
          ResRedoubleTheo(),
          "data",
          recharger,
          {
            id_org: filtre?.value || null,
            id_exam: data[0]?.id_exam || null,
            id_cent: filtre?.centre || null
          }
        );

        const columns = [
          {
            name: 'Nom et Prenom',
            selector: row => <div title={row?.nom_cand +" "+row?.prenom_cand}>{row?.nom_cand +" "+row?.prenom_cand}</div>,
            sortable: true,
          },
          {
            name: 'Auto-école',
            selector: row => row?.organisme?.nom_org,
            sortable: true,
          },
          {
            name: 'Ville',
            selector: row => row?.ville_cand,
            sortable: true,
          },
          {
            name: 'Province',
            selector: row => row?.province_cand,
            sortable: true,
          },
          {
            name: 'Catégorie de Permis',
            selector: row => row?.categ_permis,
            sortable: true,
          },
          {
            name: 'Email',
            selector: row => row?.email_cand,
            sortable: true,
          },
          
          {
            name: 'Examens',
            selector: row => <DetailExamen list={row?.all_examens}/>,
            sortable: false,
          }
        ];

  useEffect(() => {
    let centre = Cherche(listcentre, data[0], "id_org", "nom_org");
    let lieu = Cherche(listcentre, data[0], "id_org", "adresse_org");
    setnom({
      nom:centre,
      lieu:lieu
    });
  }, [data]);

  return (
    <div>
      <div className="">
        <DataTable
          title={
            <div className="mb-4 mt-3">
              <div className="row justify-content-end g-3">
                {" "}
                <div className="col-12 d-flex justify-content-between  ">
                  <div className="text-wrap fs-4">
                    <span className="fs-2 h1">Examen Theorique</span> <br />
                    {data[0]?.id_exam ? (
                      <React.Fragment>
                        <span className="py-5 fw-bold">Centre: </span> {nom?.nom}{" "}
                        <br />
                        <span className="py-5 fw-bold">Date d'examen: </span>
                        {moment(data[0]?.date_exam)
                          .locale("fr")
                          .format("LL")}{" "}
                        <br />
                        <span className="py-5 fw-bold">Catégorie: </span>
                        {Cherche(categ, data[0], "id_categ", "categ")} <br />
                      </React.Fragment>
                    ) : null}
                    {auto.value && data[0]?.id_exam ? (
                      <React.Fragment>
                        <span className="py-5 fw-bold">Auto-école: </span>
                        {Cherche(dataecole, auto, "value", "label")} <br />
                      </React.Fragment>
                    ) : null}
                  </div>
                  <div>
                    {list.length > 0 && load == false && (
                      <PrintResTheorie
                        list={list}
                        auto={auto}
                        categ={categ}
                        data={data}
                        dataecole={dataecole}
                        nom={nom?.nom}
                        listcentre={listcentre}
                      />
                    )}
                  </div>
                </div>
                {/* <div className="col">
                          <label className="form-label">Date d'examen</label>
                          <input className="form-control" type="date" />
                        </div> */}
              </div>
            </div>
          }
          className="custom-table"
          columns={columns}
          data={list}
          progressPending={load}
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText: "Lignes par page",
          }}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      </div>
    </div>
  );
}
