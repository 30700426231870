import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesDown,
  faArrowDown,
  faCaretSquareDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayPanel } from "primereact/overlaypanel";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button, Modal } from "react-bootstrap";
import { gettype } from "../../../../service/getApi/getTypeExamen";
import { useSelector } from "react-redux";
import ExamenEffectuer from "./ExamenEffectuer";

const containerStyle = {
  width: "100%",
  height: "420px",
};

const center = {
  lat: -18.8792,
  lng: 47.5079,
};

const CartographieLocalisation = ({ selectedCand }) => {
  console.log(selectedCand);

  const dataRedux = useSelector((state) => state.listtypeexam);
  const op = useRef(null);
  const [directions, setDirections] = useState([]);
  const [loading, setloading] = useState(false);
  const mapRef = useRef(null);

  const processDirections = async (coordinates) => {
    setloading(true);
    const MAX_WAYPOINTS = 25;

    let chunks = [];
    for (let i = 0; i < coordinates.length; i += MAX_WAYPOINTS) {
      chunks.push(coordinates.slice(i, i + MAX_WAYPOINTS));
    }

    const results = [];
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      const origin = chunk[0];
      const destination = chunk[chunk.length - 1];
      const waypoints = chunk.slice(1, -1).map((coord) => ({
        location: new window.google.maps.LatLng(coord.lat, coord.lng),
        stopover: true,
      }));

      const directionsService = new window.google.maps.DirectionsService();
      const result = await new Promise((resolve, reject) => {
        directionsService.route(
          {
            origin: new window.google.maps.LatLng(origin.lat, origin.lng),
            destination: new window.google.maps.LatLng(
              destination.lat,
              destination.lng
            ),
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              resolve(result);
            } else {
              reject(`Error fetching directions: ${status}`);
            }
          }
        );
      });

      results.push(result);
    }

    setDirections(results);
    setloading(false);
    if (mapRef.current) {
      fitMapToBounds(results);
    }
  };

  const fitMapToBounds = (results) => {
    const bounds = new window.google.maps.LatLngBounds();
    results.forEach((result) => {
      result.routes[0].overview_path.forEach((point) => {
        bounds.extend(point);
      });
    });
    mapRef.current.fitBounds(bounds);
  };

  useEffect(() => {
    if (selectedCand?.notepratique?.map_cand) {
      const regex = /Latitude: ([-\d.]+), Longitude: ([-\d.]+)/g;
      const coordinates = [];
      let match;

      while (
        (match = regex.exec(selectedCand.notepratique.map_cand)) !== null
      ) {
        const latitude = parseFloat(match[1]);
        const longitude = parseFloat(match[2]);
        coordinates.push({ lat: latitude, lng: longitude });
      }

      if (coordinates.length > 1) {
        processDirections(coordinates);
        if (mapRef.current) {
          mapRef.current.panTo(coordinates[0]);
        }
      }
    }
  }, [selectedCand]);
  const Type = (id) => {
    console.log(dataRedux);
    const element = dataRedux.find((element) => element.value == id);

    if (element !== undefined) {
      return `${element.label}`;
    } else {
      return `${id}`;
    }
  };
  useEffect(() => {
    gettype();
  }, []);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
      <div className="map-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={6}
          onLoad={(map) => (mapRef.current = map)}
        >
          {directions.map((dir, index) => (
            <DirectionsRenderer key={index} directions={dir} />
          ))}
        </GoogleMap>
        {loading && (
          <div className="loading-indicator loading-map-route">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        )}
        {/* {selectedCand?.id_cand && (
          <Button
            className="button-app"
            variant="primary"
            onClick={(e) => op.current.toggle(e)}
          >
            Info candidat{" "}
            <FontAwesomeIcon className="ms-2" icon={faCaretSquareDown} />
          </Button>
        )} */}
        {/* <OverlayPanel ref={op} showCloseIcon closeOnEscape dismissable={false}>
          
        <ExamenEffectuer list={selectedCand?.all_examens}/>
        </OverlayPanel> */}
      </div>
    </LoadScript>
  );
};

export default CartographieLocalisation;
