import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function SelectOne(props) {
  const animatedComponents = makeAnimated();
  let text = "";
  const [ito, setIto] = useState([]);
  const change = (choice) => {
    setIto(choice);
    text = "";
    text = choice.label;
      props.setFormValues({ ...props.formValues, [props.name]: text });
      props.setError({ ...props.error, [props.name]: null });
  };

  useEffect(() => {
   if (props.choix == null || props.choix == "") {
    setIto(null)
   }else{
    let categ = props.choix;
    let valueCat = [];
  valueCat.push({ value: categ, label: categ });
    setIto(valueCat);
   }
  }, [props.choix]);
  return (
    <Select
    closeMenuOnSelect
    components={animatedComponents}
    placeholder={props.placeholder}
    options={props.options}
    className={
      "form-control p-0 " + (props.error[props.name] && "is-invalid")
    }
    name="id_categ"
    value={ito}
    onChange={(choice) => change(choice)}
    noOptionsMessage={() => "plus d'options"}
    isDisabled={props.load}
    isLoading={props.load}
  />
  );
}
